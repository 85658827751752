export const rgbaToHex = (rgbaCode?: string) => {
    if (!rgbaCode) {
        return '#ffffff';
    }

    const hexValues = rgbaCode.match(/[0-9|.]+/g);

    if (!hexValues || hexValues.length < 3) {
        return '#ffffff';
    }

    const hexString = hexValues
        .map((x, i) => (i > 2 ? parseInt((255 * parseFloat(x)).toString()).toString(16) : parseInt(x).toString(16)))
        .join('');

    return `#${hexString}`;
};

export const hexToRGBA = (hexCode?: string, opacity?: number) => {
    if (!hexCode) {
        return 'rgba(255, 255, 255, 1)';
    }

    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    if (!opacity) {
        return `rgba(${r}, ${g}, ${b}, 0)`;
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
